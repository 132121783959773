.App {
  text-align: center;
  font-size: xx-large;
  font-family: monospace;
}

input {
  width: 120px;
  font-size: xx-large;
}

.currency {
  margin-top: 0.5em;
  vertical-align: middle;
}